'use client';

import AgenciaLandpage from './agencia-landpage';
import AntecipameLandpage from './antecipame-landpage';
import CelyLandpage from './cely-landpage';
import HummanamaisLandpage from './hummanamais-landpage';
import IncluaLandpage from './inclua-landpage';
import MedsimplesLandpage from './medsimples-landpage';
import { useTenantConfig } from './providers/tenant_provider';

export default function Home() {
  const config = useTenantConfig();

  switch (config?.landpage) {
    case 'antecipame':
      return <AntecipameLandpage />;
    case 'agencia':
      return <AgenciaLandpage />;
    case 'hummanamais':
      return <HummanamaisLandpage />;
    case 'inclua':
      return <IncluaLandpage />;
    case 'cely':
      return <CelyLandpage />;
    default:
      return <MedsimplesLandpage />;
  }
}
